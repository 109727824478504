import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>IN-HOUSE COUNSEL</h2><hr/>
    <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>Member of the Integrated Bar of the Philippines</li>
          <li>Should have experience in corporate compliance</li>
          <li>Should have experience having worked with a financial institution or other regulated business, such as banks, lending companies or financial technology companies</li>
          <li>Excellent communication and analytical skills</li>
          <li>Has high attention to detail and is well-organized</li>
          <li>Experience in performing due diligence work is preferred </li>
        </ul>
      </Modal.Body>
      <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
